<template>
    <div class="main-container">
        <div class="thank-you">¡Gracias por la compra!
            <span class="material-icons-outlined check">
                done
            </span>
        </div>
        <div class="carrito-descripcion">
            <div v-for="product in products" :key="product.id" class="product-container">
                <div class="product">
                    <div class="product-image">
                        <img src="@/views/assets/empty-cart.png" width="180px" height="160px" alt="Empty Cart">
                    </div>
                    <div class="product-name">
                        <div class="product-name-name">
                            Articulo: {{ product.product_name }}
                        </div>
                        <div class="product-details">
                            Precio unitario: {{ product.price }} €
                        </div>
                        <div class="product-details">
                            Cantidad: {{ product.quantity }}
                        </div>
                        <div class="product-details total">
                            Total: {{ product.quantity * product.price }} €
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <div class="retorno-graficador">
                <a href="/app/graficador-dinamico/"><button class="back-button">Volver al graficador</button></a>
            </div>
            <div class="retorno-graficador">
                <a href="/app/store/"><button class="back-button">Seguir comprando</button></a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
    mixins: [APICaller],
    name: 'Confirmacion de compra',
    components: {},
    data() {
        return {
            products: [],
            confirmated_token: '',
        }
    },
    mounted() {
        var url = new URL(window.location.href);
        var params = new URLSearchParams(url.search);
        var urlObj = new URL(url);
        var token = urlObj.searchParams.get('token');

        this.loadMetadata();
        var waiting_for_token = setInterval(()=>{
            if(this.confirmated_token != undefined && this.confirmated_token && this.confirmated_token != ""){
                if(this.confirmated_token == token){
                    this.activateFeatures();                    
                }else{
                    window.location.href = '/app/product-confirmation/error'
                }
                clearInterval(waiting_for_token);
            }
        }, 20)
    },
    computed: {
        ...mapGetters(['_g_IsUserLogged', '_g_UserId'])
    },
    methods: {
        async loadMetadata() {
            await this._getTokenFromMetadata();
        },

        async loadData() {
            await this._deleteTokenFromMetadata();
            await this._getProducts();
            await this.loadMetadata();
        },

        activateFeatures(){
            let success = (response) => {
                this.loadData();
            };

            let url = '/api/v1/eurekers-user-features/activate-features';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('0199', 'Error recuperando información de usuario.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        async _getTokenFromMetadata() {
            var field_metadata = 'purchase_token';
            let success = (response) => {
                this.confirmated_token = response.data.value;
            };

            let url = '/api/v1/users/user/get-user-metadata/' + field_metadata + '/' + this._g_UserId;

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('018', 'Error recuperando información de usuario.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        async _deleteTokenFromMetadata() {
            var field_metadata = 'purchase_token';
            let params = new URLSearchParams();
            params.append('user_id', JSON.stringify(this._g_UserId));
            params.append('value', JSON.stringify(''));

            let url = "/api/v1/users/user/post-user-metadata/" + field_metadata;

            const successHandler = new APICaller.SuccessHandler();
            const failureHandler = new APICaller.FailureHandler('235', 'Error actualizando información de usuario.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },

        async _getProducts() {
            let success = response => {
                this.products = [];
                this.amount = response.data.value.amount;
                let prod = response.data.value.products;
                for (let key in prod) {
                    this.products.push(prod[key]);
                }
                this._deleteCart();
            }

            let url = "/api/v1/eurekers_marketplace/get-cart";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('510', 'Error al recuperar los productos.');

            await this._getAPICall(url, successHandler, failureHandler);
        },
        async _deleteCart() {
            let success = _ => { }

            let url = "/api/v1/eurekers_marketplace/delete-cart";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('510', 'Error al recuperar los productos.');

            await this._getAPICall(url, successHandler, failureHandler);
        }
    },
}
</script>
<style scoped>
.thank-you {
    font-size: 35px;
    text-align: center;
    padding-top: 6%;
}

.carrito-descripcion {
    text-align: center;
    padding: 50px;
    font-size: 20px;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 13px !important;
}

.product {
    display: flex;
    padding: 30px;
}

.product-image {}

.product-details {
    font-size: 15px;
}

.product-name {
    margin-top: 2px;
    margin-left: 50px;
    text-align: left;
    width: 100%;
}

.product-name>* {
    margin-top: 10px;
}

.product {
    border: 2px solid rgba(139, 139, 139, 0.532);
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 20px;
}

.back-button {
    background-color: #00ADD9FF;
    color: aliceblue;
    border-radius: 15px;
    padding: 15px;
    border: 1px solid white;
}

.retorno-graficador {
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
}

.check {
    font-size: 40px;
    color: rgb(2, 184, 2);
    padding-top: 12px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button {
    cursor: pointer;
}
</style>